import React from 'react';
import './styles.scss';

const ThankYou = () => (
  <div className="thank-you">
    <h1>THANK YOU!</h1>
    <div>We’ll be in touch with you soon!</div>
  </div>
);

export default ThankYou;
