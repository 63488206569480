import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './styles.scss';

const CoachDialog = ({ description, imageUrl, name, onClose, open }) => (
  <Dialog
    open={open}
    onClose={onClose}
    className="coach-dialog"
    aria-labelledby="coach-dialog-title"
    aria-describedby="coach-dialog-description"
  >
    <DialogContent className="dialog-content">
      <img src={imageUrl} alt={name} />
      <h3 id="coach-dialog-title">{name}</h3>
      <DialogContentText id="coach-dialog-description">
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default CoachDialog;
