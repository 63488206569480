import React from 'react';

export default [
  {
    id: 'Mock Weightlifting Meet',
    title: 'Mock Weightlifting Meet',
    date: (
      <div>
        <s>
          Sunday, April 5, 2020 <span className="nowrap">at 8:30am</span>
        </s>
      </div>
    ),
    imageUrl: '/images/events/2020-04-05--mock-weightlifting-meet.jpeg',
    description:
      'Open to All in the Dauntless Community: Please join us for our first Mock Weightlifting Meet!',
    url: 'https://www.facebook.com/events/2368554556717118/',
  },
  {
    id: 'Dauntless Date Night with Live Music',
    title: 'Dauntless Date Night with Live Music',
    date: (
      <div>
        <s>
          Friday, April 3, 2020 <span className="nowrap">at 7:00pm</span>
        </s>
      </div>
    ),
    imageUrl: '/images/events/2020-04-03--date-night.jpeg',
    description:
      "Let's get together and enjoy a beverage or two at the Blue Mermaid while listening to live music from our one and only Drew!",
    url: 'https://www.facebook.com/events/172371164053566/',
  },
  {
    id: 'Dauntless Turns TWO!',
    title: 'Dauntless Turns TWO!',
    date: (
      <div>
        <s>
          Saturday, March 21, 2020 <span className="nowrap">at 9:00am</span>
        </s>
      </div>
    ),
    imageUrl: '/images/events/2020-03-21--dauntless-turns-two.jpeg',
    description:
      "Please join us at 10am for 1 adult class and 1 kid's class followed by some fun!",
    url: 'https://www.facebook.com/events/2615039768764761/',
  },
];
