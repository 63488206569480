const TRACKING_ID =
  window.location.hostname === 'dauntlessnh.com'
    ? 'UA-156926189-1'
    : 'UA-156926189-2';

export const initialize = () => {
  if (window.ga) {
    window.ga('create', TRACKING_ID, 'auto');
  }
};

export const pageview = page => {
  if (window.ga) {
    window.ga('set', 'page', page);
    window.ga('send', 'pageview');
  }
};

export const click = (
  eventAction,
  { eventCategory = 'click', eventLabel, eventValue, useBeacon } = {}
) => {
  if (window.ga) {
    window.ga('send', 'event', {
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      ...(!!useBeacon && { transport: 'beacon' }),
    });
  }
};
