import React, { PureComponent } from 'react';
import WorkoutSummary from './WorkoutSummary';
import { getWorkouts } from '../../api/btwbApi';
import './styles.scss';

const Error = () => (
  <div className="error">
    <div>Unable to load at this time.</div>
    <div>
      Please visit{' '}
      <a href="https://www.beyondthewhiteboard.com/gyms/5813-exetercf/wods">
        Beyond the Whiteboard
      </a>{' '}
      to see more workout information.
    </div>
  </div>
);

class Workout extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { data: null, error: false };
  }

  static getDerivedStateFromError = () => ({ error: true });

  componentDidMount = () => {
    getWorkouts()
      .then(data => this.setState({ data }))
      .catch(() => this.setState({ error: true }));
  };

  render = () => {
    return (
      <div className="workout">
        {this.state.error ? (
          <Error />
        ) : (
          <WorkoutSummary data={this.state.data} />
        )}
      </div>
    );
  };
}

export default Workout;
