import PATHNAMES from './pathnames';

export default [
  {
    label: 'FITNESS',
    path: PATHNAMES.FITNESS,
    subItems: [
      {
        label: 'ABOUT US',
        to: 'about-us',
      },
      {
        label: 'GROUP FITNESS CLASSES',
        to: 'group-fitness-classes',
      },
      {
        label: 'PERSONAL TRAINING',
        to: 'personal-training',
      },
      {
        label: 'DAUNTLESS YOUTH',
        to: 'dauntless-youth',
      },
      {
        label: 'DAUNTLESS KIDS',
        to: 'dauntless-kids',
      },
      {
        label: 'WORKOUT',
        to: 'workout',
      },
      {
        label: 'LOCATION',
        to: 'location',
      },
    ],
  },
  {
    label: 'NUTRITION',
    path: PATHNAMES.NUTRITION,
  },
  {
    label: 'COMMUNITY',
    path: PATHNAMES.COMMUNITY,
    subItems: [
      {
        label: 'UPCOMING EVENTS',
        to: 'upcoming-events',
      },
      {
        label: 'TESTIMONIALS',
        to: 'testimonials',
      },
      {
        label: 'BLOG',
        to: 'blog',
      },
      {
        label: 'COACHES',
        to: 'coaches',
      },
      {
        label: 'MOBILITY',
        to: 'mobility',
      },
    ],
  },
];
