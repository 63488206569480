import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ScrollLink from '../../ScrollLink';
import MENU from '../../../constants/menu';
import './styles.scss';

const MenuItem = ({ path, to, className, children }) => (
  <ScrollLink
    path={path}
    to={to}
    render={onClick => (
      <Button disableRipple className={className} onClick={onClick}>
        {children}
      </Button>
    )}
  />
);

const TopLevelMenuItem = ({ path, children }) => (
  <MenuItem className="top-level-item" path={path}>
    {children}
  </MenuItem>
);

const DropdownMenuItem = ({ label, path, children }) => (
  <div className="dropdown">
    <TopLevelMenuItem path={path}>
      {label} <ArrowDropDownIcon />
    </TopLevelMenuItem>
    <div className="dropdown-content">{children}</div>
  </div>
);

const DesktopMenu = () => (
  <div className="menu-bar">
    {MENU.map(({ label, path, subItems }) =>
      subItems ? (
        <DropdownMenuItem key={path} label={label} path={path}>
          {subItems.map(({ label, to }) => (
            <MenuItem key={to} path={path} to={to}>
              {label}
            </MenuItem>
          ))}
        </DropdownMenuItem>
      ) : (
        <TopLevelMenuItem key={path} path={path}>
          {label}
        </TopLevelMenuItem>
      )
    )}
  </div>
);

export default DesktopMenu;
