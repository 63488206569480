import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import blogs from '../../constants/blogs';
import './styles.scss';

class Blog extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const blog = find(blogs, ['url', this.props.location.pathname]);

    if (!blog) {
      this.props.history.push('/community');
      return null;
    }

    const { title, imageUrl, imageUrlFull, description } = blog;

    return (
      <div className="blog">
        {(imageUrlFull || imageUrl) && (
          <img src={imageUrlFull || imageUrl} alt={title} />
        )}
        <h1>{title}</h1>
        <section
          className="description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    );
  }
}

export default withRouter(Blog);
