import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import * as ga from '../../utils/ga';
import scrollTo from '../../utils/scrollTo';
import './styles.scss';

const ComingSoon = () => (
  <section className="coming-soon">
    <div>
      While we update our online nutrition content, contact us to learn more
      about our nutrition program!
    </div>
    <div className="contact-wrapper">
      <Button
        id="contact-button"
        href="mailto:javants@dauntlessnh.com?subject=Interested%20In%20Dauntless%20Nutrition"
        onClick={() =>
          ga.click('Contact Our Dietitian Button', { useBeacon: true })
        }
      >
        Contact Our Dietitian!
      </Button>
    </div>
  </section>
);

const NutritionVideo = () => (
  <section className="nutrition-video">
    <img alt="placeholder" className="ratio" src="http://placehold.it/16x9" />
    <iframe
      title="dauntless-nutrition"
      src="https://www.youtube.com/embed/MK4xQtIDol0"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen="allowfullscreen"
    ></iframe>
  </section>
);

class Nutrition extends PureComponent {
  componentDidMount() {
    if (this.props.history.location.hash) {
      scrollTo(this.props.history.location.hash.substr(1));
    }
  }

  render() {
    return (
      <div className="nutrition">
        <ComingSoon />
        <NutritionVideo />
      </div>
    );
  }
}

export default withRouter(Nutrition);
