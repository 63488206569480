import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@mdi/react';
import { mdiFacebook, mdiInstagram } from '@mdi/js';
import * as ga from '../../utils/ga';
import './styles.scss';

const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

const InstagramIcon = () => (
  <a
    href="https://www.instagram.com/dauntless_academy/"
    target="_blank"
    rel="noreferrer noopener"
  >
    <IconButton onClick={() => ga.click('Instagram Icon', { useBeacon: true })}>
      <Icon
        path={mdiInstagram}
        title="Instagram"
        color="#ffffff"
        size="1.5em"
      />
    </IconButton>
  </a>
);

const FacebookIcon = () => (
  <a
    href="https://www.facebook.com/DauntlessNH/"
    target="_blank"
    rel="noreferrer noopener"
  >
    <IconButton onClick={() => ga.click('Facebook Icon', { useBeacon: true })}>
      <Icon path={mdiFacebook} title="Instagram" color="#ffffff" size="1.5em" />
    </IconButton>
  </a>
);

class Footer extends PureComponent {
  render = () => (
    <div className="footer">
      <div className="copyright">
        © {getCurrentYear()} Dauntless Academy, LLC all rights reserved
      </div>
      <div className="email">
        <a
          href="mailto:info@dauntlessnh.com"
          target="_blank"
          rel="noreferrer noopener"
          onClick={() => ga.click('Email Link (Footer)', { useBeacon: true })}
        >
          info@dauntlessnh.com
        </a>
      </div>
      <div className="social-media-links">
        <InstagramIcon />
        <FacebookIcon />
      </div>
    </div>
  );
}

export default Footer;
