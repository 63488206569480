import React, { PureComponent } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import Theme from '../Theme';
import Footer from '../Footer';
import GAPageViewTracker from '../GAPageViewTracker';
import HashStripper from '../HashStripper';
import Header from '../Header';
import Blog from '../Blog';
import Community from '../Community';
import ContactUs from '../ContactUs';
import Fitness from '../Fitness';
import Nutrition from '../Nutrition';
import Testimonial from '../Testimonial';
import ThankYou from '../ThankYou';
import PATHNAMES from '../../constants/pathnames';
import * as ga from '../../utils/ga';

class App extends PureComponent {
  componentDidMount = () => {
    ga.initialize();
  };

  render = () => (
    <Theme>
      <BrowserRouter className="app">
        <GAPageViewTracker />
        <HashStripper />
        <Header />
        <div className="main">
          <Switch>
            <Route path={PATHNAMES.BLOG}>
              <Blog />
            </Route>
            <Route exact path={PATHNAMES.COMMUNITY}>
              <Community />
            </Route>
            <Route exact path={PATHNAMES.FITNESS}>
              <Fitness />
            </Route>
            <Route exact path={PATHNAMES.NUTRITION}>
              <Nutrition />
            </Route>
            <Route exact path={PATHNAMES.CONTACT_US}>
              <ContactUs />
            </Route>
            <Route path={PATHNAMES.TESTIMONIAL}>
              <Testimonial />
            </Route>
            <Route exact path={PATHNAMES.THANK_YOU}>
              <ThankYou />
            </Route>
            <Redirect exact from="/" to={PATHNAMES.FITNESS} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </Theme>
  );
}

export default App;
