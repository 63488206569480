import React, { useState } from 'react';
import { Element } from 'react-scroll';
import coaches from '../../../constants/coaches';
import CoachCard from './CoachCard';
import CoachDialog from './CoachDialog';
import './styles.scss';

const Coaches = () => {
  const [selectedCoach, setSelectedCoach] = useState(null);

  return (
    <section className="coaches">
      <Element name="testimonials">
        <h3>COACHES</h3>

        <div className="testimonial-cards">
          {coaches.map(coach => (
            <CoachCard
              key={coach.name}
              coach={coach}
              onClick={setSelectedCoach}
            />
          ))}
        </div>
      </Element>
      <CoachDialog
        {...(selectedCoach ?? {})}
        open={!!selectedCoach}
        onClose={() => setSelectedCoach(null)}
      />
    </section>
  );
};

export default Coaches;
