export default [
  {
    name: 'Elliott Field',
    imageUrl: '/images/coaches/elliott-field.jpeg',
    tagline: `
      <strong>CrossFit Level 3 Certified</strong><br />
      Likes Dogs Named Ollie<br />
      Dislikes Asparagus
    `,
    description: `
      <p><strong>This is some bold text that can be used to make something stand out.</strong></p>
      <p>I can write lots of information in a paragraph format. I can also include links to other things.</p>
      <ul>
        <li>I can also insert a list</li>
        <li>This is a second item</li>
        <li>This is a third item</li>
      </ul>
      <blockquote><i>I could even close it out with an inspirational quote.<br />--Michael Scott</i></blockquote>
    `,
  },
  {
    name: 'Megan Trombley',
    imageUrl: '/images/coaches/megan-trombley.jpeg',
    tagline: `
      <strong>CrossFit Level 2 Certified</strong><br />
      Cat Lover<br />
      Once Walked 24-hours for a Fundraiser
    `,
    description: `
      <p><strong>This is some bold text that can be used to make something stand out.</strong></p>
      <p>I can write lots of information in a paragraph format. I can also include links to other things.</p>
      <ul>
        <li>I can also insert a list</li>
        <li>This is a second item</li>
        <li>This is a third item</li>
      </ul>
      <blockquote><i>I could even close it out with an inspirational quote.<br />--Michael Scott</i></blockquote>
    `,
  },
];
