import React from 'react';
import { Element } from 'react-scroll';
import events from '../../../constants/events';
import EventCard from './EventCard';
// import EventsComingSoon from './EventsComingSoon';
import './styles.scss';

const UpcomingEvents = () => (
  <section className="upcoming-events">
    <Element name="upcoming-events">
      <h3>UPCOMING EVENTS</h3>

      <div className="event-cards">
        {/* <EventsComingSoon /> */}
        {events.map(event => (
          <EventCard key={event.url} {...event} />
        ))}
      </div>
    </Element>
  </section>
);

export default UpcomingEvents;
