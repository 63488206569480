import React from 'react';
import withViewportSize from 'with-viewport-size';
import ContactButton from '../ContactButton';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const Menu = ({ toggleDrawer, viewport }) =>
  viewport.width >= 950 ? (
    <>
      <DesktopMenu />
      <div style={{ flexGrow: 1 }} />
      <ContactButton />
    </>
  ) : (
    <>
      <div style={{ flexGrow: 1 }} />
      <ContactButton />
      <MobileMenu toggleDrawer={toggleDrawer} />
    </>
  );

export default withViewportSize(Menu);
