import { get } from './_api';
import * as TRACKS from '../constants/tracks';

const TRACK_IDS = Object.values(TRACKS).map(({ id }) => id);

const PARAMS = {
  days: 1,
  track_ids: TRACK_IDS.join(','),
  sections: 'all',
  activity_length: 0,
  leaderboard_length: 0,
};

const QUERY_PARAMS = Object.entries(PARAMS)
  .map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  )
  .join('&');

const URL = `https://webwidgets.prod.btwb.com/webwidgets/wods?${QUERY_PARAMS}`;

const HEADERS = {
  authorization: 'Bearer wg4dpn0ngnm21bitbz3fvviu',
};

export const getWorkouts = () => get(URL, { headers: HEADERS });
