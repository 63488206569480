export default [
  {
    title: 'Erin Vosgien',
    imageUrl: '/images/testimonials/erin-vosgien.jpeg',
    description: `
    <h3>“This has completely reshaped how I look at food. I genuinely want to continue with what I’ve learned not only because I’ve seen the results but I can feel them. I feel better than I have in years. I’ve regained my willpower, and I feel like I’ve retaken control over an area of my life that I’d lost.”</h3>
    <div>This month, we’d like to introduce you to Erin Vosgien, who has been a member since June of 2018.  Many of you may not know her as she is one of our quiet warriors who normally comes to our 530am class.  We’re writing about Erin for two reasons.  First, she inspires us with her dedication and grit.  Second, she recently stepped into her fears to take control of a key factor of her health that she felt had been holding her back.  Erin graciously volunteered to pilot our newly overhauled nutrition program. She was gracious enough to discuss her experience with the program.</div>

    <div style="font-weight: bold">Why did you decide to start the nutrition program?</div>
    <div>-When you first reached out to me about testing the program, my initial thought was “No.” And that knee jerk reaction told me that I absolutely had to try it. I knew my hesitation was because I was worried it would be hard, restrictive, and time consuming. But I also knew that I needed to make changes to my diet; it was an area I hadn’t yet touched but I knew I needed to, the same way I knew I needed to bite the bullet and sign up for the gym after the initial workout. There would be no change or improvement without taking the risk and going out of my comfort zone. And I liked the idea of being able to get personal feedback and instruction versus trying to do some general one-size-fits-no-one crash diet that wouldn’t last past a few months.</div>

    <div style="font-weight: bold">What did you value most about the whole program?</div>
    <div>-The two key factors that make this program stand out are 1) it was tailored to me and 2) it focused on real food for real life. Every part of my plan was designed around foods I liked, what worked with my/our schedule, what fit in my comfort zone. It was created to make me succeed, and that was clear from the first week of menus. And from there, each week Jacqui would work in what we discussed: crock pot meals to help with busy weeknights, different veggie sides to mix up our average go-to’s. But all of the menus centered on eating/cooking real food, not liquid diets, not huge fasting times. […] The focus was on changing and improving habits not just on losing weight. I never felt deprived, or like I had no options. And the habits have become second nature.  I really don’t even have to think about them now. It’s so much easier to make better choices, and better yet, I want to make them. I don’t feel deprived so there is no desire to dive off of the deep end and gorge on garbage.</div>

    <div style="font-weight: bold">Why did you decide to continue the program after the foundations month?</div>
    <div>-It was tremendously helpful to have that weekly accountability to keep in check and also keep me pushing towards other […]. I was nervous about taking this on during the holidays, wondering if I was setting myself up to fail. But the program was a life saver. […] Jacqui helped shift my mindset so that instead of getting stressed and reaching for junk, I’d go for a walk with the dog, grab some water and a healthy snack, and that kept me feeling great and wanting to continue. And right after Christmas we were heading out for vacation, and I really wanted to avoid coming home feeling like garbage from having missed not only normal workouts but also eating junk for 10 days. Jacqui helped me formulate a plan to tackle traveling and being off schedule but still stick to the basics of what I was following in the plan so that I didn’t complete derail myself while we were away. What I’ve learned/continue to learn are engrained so that I can continue seeing success.</div>

    <div style="font-weight: bold">How has the nutrition program enhanced your life?</div>
    <div>-This has completely reshaped how I look at food. I genuinely want to continue with what I’ve learned not only because I’ve seen the results but I can feel them. I feel better than I have in years. I’ve regained my willpower, and I feel like I’ve retaken control over an area of my life that I’d lost. I don’t need to seek comfort from food anymore; I use it now to fuel me through my day. What is going to sustain me and keep me feeling good as I tackle work, kid activities, etc. I don’t think “oh, I worked out this morning so I’ll treat myself to this” or “where going out tonight so I won’t eat all day so I can get this for dinner.” I genuinely want to make good choices each day, each meal. I feel more confident, lighter but also stronger. And it feels amazing to do that, feel that way without depriving myself or comprising on things.</div>

    <div style="font-weight: bold">How was your family benefited by this decision?</div>
    <div>-I knew my husband was on the fence about my decision at first, I think for the same reasons I initially hesitated to do it. But once he looked through the materials Jacqui gave me at the on-ramp and looked over the menu, he saw that this was a totally different program.  This was not a diet but rather rethinking nutrition on a bigger level. Both he and the kids have liked the new recipes that we’ve been making and enjoying actually cooking again, not just grabbing stuff from boxes or the freezer, but actually planning and making real meals. I see them all also making better food choices based on what they’re seeing me do. My six year old helped me make a batch of energy bites the other day and actually liked them, so I count that as a win.</div>

    <div style="font-weight: bold">What was something you didn’t expect, that you got out of the program?</div>
    <div>-That it would so completely reshape how I look at and approach food. To say that this program changed my life may sound dramatic, but it really did. I can’t imagine going back to how I was eating before this. Initially I thought I’d maybe lose a few pounds, gain a couple of new recipes to add into our meal planning, that was it. I don’t weight myself ever, so during the four weeks, while I felt great and felt like it was working, I had no real idea of what my results might be. When I met with Jacqui to take the end measurements (still refused to look at the scale), I thought at most I’d be down two to three pounds. I wasn’t expecting eight. It had felt too easy, too natural. And that really convinced me that this was something that I wanted to continue. To see and feel tangible results while making easy, manageable changes to my overall eating habits without ever feeling like I was on a “diet” left me feeling recharged and feeling like I had a newfound control and balance over that piece of my life.</div>

    <div style="font-weight: bold">What was something that was holding you back from trying the program?</div>
    <div>-Myself and cost. I didn’t want to commit to a plan that was going to have me counting calories, eating the same thing day and out, feeling stuck in a rut that I wouldn’t be able to maintain after the diet was over. I’ve also always tried to be mindful of how I present food and nutrition to my kids, hoping they avoid developing unhealthy or negative views about food. So I didn’t want to follow a plan that would have me strictly measuring quantities, restricting what and when to eat, etc. And because we have two kids, both of whom are involved in numerous sports and activities, we have to keep budget in mind, so overall cost is always a factor.</div>

    <div>We want to thank Erin for trusting us with her journey.  It’s a hard decision to step into something new like this at any time of the year, especially the holidays.  Congratulations to Erin on her hard work and great results!</div>
      `,
    url: '/testimonial/erin-vosgien',
  },
  {
    title: 'Antoine Corbeil',
    imageUrl: '/images/testimonials/antoine-corbeil.jpeg',
    imageUrlFull: '/images/testimonials/antoine-corbeil-full.jpeg',
    description: `<div>For those of you who don’t know, we’d like to introduce to you one of our rock star Dauntless family members:  Antoine Corbeil.  Antoine can usually be found at our 530am classes (immediate points for getting up that early, especially in the winter) and has been with us for almost a year.</div>
      <div>We wanted to highlight Antoine for his inspiring transformation.  Antoine is an engineer and has been, in his own words, “riding a desk” for many years.  This led him to be a bit out of shape.  So much so, that he found he was unable to keep up with his daughters when running around outside. One of his friends, another Dauntless member, recommended giving us a try.  Antoine completed the on ramp and decided that “it probably wouldn’t kill me.”  One year later, Antoine is almost 30 pounds lighter and off of his medications.  He’s also fond of saying that his girls definitely like “strong daddy” versus “fat daddy.”  (That line was our favorite part of the whole discussion.)</div>
      <div>It’s important to us to acknowledge amazing people.  Antoine’s transformation is nothing short of inspiring and a testament to his hard work and grit.  He should be very proud of himself and we’re very proud of having him as a part of the Dauntless Family.</div>`,
    url: '/testimonial/antoine-corbeil',
  },
];
