import React from 'react';
import { Element } from 'react-scroll';
import './styles.scss';

const Mobility = () => (
  <section className="mobility">
    <Element name="mobility">
      <h3>MOBILITY</h3>
      <div className="dauntless-mobility-wrapper">
        <img
          alt="placeholder"
          className="ratio"
          src="http://placehold.it/16x9"
        />
        <iframe
          title="dauntless-mobility"
          src="https://www.youtube.com/embed/videoseries?list=PLTKqIhmgklyDQWOtD3v27Nqxr7y-n_nq_"
          frameBorder="0"
          allow="encrypted-media"
          allowFullScreen="allowfullscreen"
        ></iframe>
      </div>
    </Element>
  </section>
);

export default Mobility;
