export default [
  {
    title: 'Benefits of Youth Training',
    imageUrl: '/images/blogs/benefits-of-youth-training.png',
    description: `<div>

      <p>As parents, few things are as exciting as watching our kids play in, and excel at, sports they love. It’s simultaneously exhausting and exhilarating for us, and it can also be nerve wracking.</p>

      <p>For children who are heavily active in sports, is there such a thing as too much? Is my daughter or son prepared for long hours of repetitive  movements? Are their muscles/connective tissues/bones/growth plates prepared?</p>

      <p>That depends, and it’s something we should think about…</p>

      <p>It’s long been accepted that strength training is beneficial for adults. It increases muscle mass, burns fat, ramps up our metabolism and helps our bodies run more efficiently. Over the past decade, research has shown that youth and teens also benefit from strength training. This is even more critical for young athletes who are specializing in a few sports year round.</p>

      <p>The Mayo Clinic has found that<sup>1</sup>:</p>

      <p>“Done properly, strength training can:</p>

      <ul>
        <li>Increase your child's muscle strength and endurance</li>
        <li><strong>Help protect your child's muscles and joints from sports-related injuries</strong></li>
        <li><strong>Help improve your child's performance in nearly any sport, from dancing and figure skating to football and soccer (emphasis mine)</strong></li>
        <li>Develop proper techniques that your child can continue to use as he or she grows older”</li>
      </ul>

      <p>The American Academy of Pediatrics agrees<sup>2</sup>, writing that resistance training can serve as a “prehabilitation” for muscles and joints that will experience various levels of loads and stresses in sports. Specifically:</p>

      <blockquote>“Preventive exercise (prehabilitation) uses resistance training to address and focus on joints that are commonly at risk for overuse injuries. Research in adolescent athletes has shown resistance training to contribute to decreased injuries.”</blockquote>

      <p>The human body is a structure. During sports, that structure is stressed in different planes of movement under various loads and timeframes. LIke any other structure, it needs to have a strong foundation and be built properly with purpose in mind. That foundation is built with appropriate resistance training. A well designed strength training program can help strengthen your child’s connective tissues and muscles for the rigors of sports, making them more resistant to injury and promoting longevity.</p>

      <p>At Dauntless, our Coaches have the experience to provide that guidance and appropriate level of resistance training. Let us help prepare your child for durability and longevity to keep participating in the sports they love.</p>

      <p>Email us today for a schedule and how we can help your child/children prepare for a lifetime of fun and fulfilling sports: <a href="mailto:DauntlessNH@gmail.com">DauntlessNH@gmail.com</a></p>

      <hr />

      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="line-height: 1">
        <tr>
          <td><sup>1</sup></td>
          <td>
            <i>
              <a target="_blank" rel="noreferrer noopener" href="https://www.mayoclinic.org/healthy-lifestyle/tween-and-teen-health/in-depth/strength-training/art-20047758">
                https://www.mayoclinic.org/healthy-lifestyle/tween-and-teen-health/in-depth/strength-training/art-20047758
              </a>
            </i>
          </td>
        </tr>
        <tr>
          <td style="vertical-align: top"><sup>2</sup></td>
          <td>
            <i>
              <a target="_blank" rel="noreferrer noopener" href="https://pediatrics.aappublications.org/content/145/6/e20201011">https://pediatrics.aappublications.org/content/145/6/e20201011</a><br />
              <a target="_blank" rel="noreferrer noopener" href="https://smgortho.com/avoiding-overuse-injuries-youth-sports/">https://smgortho.com/avoiding-overuse-injuries-youth-sports/</a><br />
              <a target="_blank" rel="noreferrer noopener" href="https://orthoinfo.aaos.org/en/staying-healthy/a-guide-to-safety-for-young-athletes/">https://orthoinfo.aaos.org/en/staying-healthy/a-guide-to-safety-for-young-athletes/</a>
            </i>
          </td>
        </tr>
      </table>

    </div>`,
    url: '/blog/benefits-of-youth-training',
  },
  {
    title: 'Exercise Boosts Immunity',
    imageUrl: '/images/blogs/exercise-boosts-immunity.png',
    description: `<div>
    
      <p>On 9/5, the World Health Organization posted an infographic showing the latest findings from the UN Interagency Task Force on Non-Communicable Diseases. The graphic showed best practices for reducing health risks from COVID-19, and we were pleased to see fitness on that list!</p>
      
      <p>Check out some of the key points:</p>

      <ul>
        <li>Eat a healthy diet</li>
        <li><strong>Be physically active</strong></li>
        <li>Obesity can increase the risk of a severe COVID-10 infection by <strong><u>7 fold</u></strong></li>
        <li>The risks of severe COVID infection also increase if you have high blood pressure, heart disease and diabetes</li>
        <li><strong>“Healthier people can more easily fight off disease”</strong></li>
      </ul>

      <p><strong>Which begs the question, does exercise boost immunity? The answer is a resounding, probably yes.</strong></p>

      <p>To be clear, there is no definitive study showing that statement to be 100% accurate. The fact is, there is no solid way to measure the efficacy of a person’s immune system. There is no way to know why one person can be knocked on their rear by the lowly and prolific common-cold coronavirus (yes, the common cold is also a type of coronavirus) and yet their roommate barely registers a sniffle.</p>

      <p>However, scientists can measure mobilization and response of a person’s immune system. They can measure how many of the various cells and cytokines (the protein signals that tell your immune system when to go on the attack) are moving and floating around in your blood and lymphatic systems.</p>

      <p>The most helpful source I read was a very riveting article published in the Journal of Sport and Health Science titled <u><i>“The compelling link between physical activity and the body's defense system.”</i></u><sup>1</sup>  The article has many fascinating words like “immunosenescence” so you know it’s legit.</p>

      <p>Without getting too super-sciency, here are some of the critical points:</p>

      <ul>
        <li>Several epidemiologic studies suggest that regular physical activity is associated with decreased mortality and incidence rates for <a href="https://www.sciencedirect.com/topics/social-sciences/influenza" target="_blank" rel="noreferrer noopener">influenza</a> and <a href="https://www.sciencedirect.com/topics/medicine-and-dentistry/pneumonia" target="_blank" rel="noreferrer noopener">pneumonia</a>.</li>
        <li>There is also increasing support for improved <a href="https://www.sciencedirect.com/topics/social-sciences/immunology" target="_blank" rel="noreferrer noopener">antibody</a> responses to influenza immunization in elderly adults who engage in regular exercise training regimens.</li>
        <li>There is increasing evidence that regular exercise training has an overall anti-inflammatory influence. (Dauntless note: Chronic inflammation is thought to be a significant contributor to many illnesses.)</li>
        <li>Randomized clinical trials and <a href="https://www.sciencedirect.com/topics/medicine-and-dentistry/cohort-effect" target="_blank" rel="noreferrer noopener">epidemiologic studies</a> consistently support the inverse relationship between moderate exercise training and incidence of upper respiratory tract infections.</li>
      </ul>

      <p><strong>To put it plainly, the more people exercise the fewer incidences of respiratory tract infections they experience.</strong>  The reigning theory is two fold. As you exercise, you are breaking down muscle in order to build it back bigger and stronger. Your immune system mobilizes, creating more of the cells and signaling proteins it needs to respond AND kicking their movement into high gear by flushing your body with blood and lymph. Basically, your body makes more immunity tools and floods them all over, better positioning them to respond to any nasty bugs that may try to attack.</p>

      <p>The type and intensity of exercise matters. Studies found that overdoing it, such as frequent training for marathons and ultramarathons had the opposite effect on immunity. Training of that duration and intensity actually overwhelmed the body's immune system as it couldn’t clean up the oxidation and muscle damage AND battle bacteria and viruses.</p>

      <p>Moderate intensity makes you healthier and boosts your immune system. Like, all things moderation is key. Live a balanced, healthy lifestyle. Eat well, don’t smoke, get your exercise several times per week.</p>

      <p>If you're not sure where to start, call us. We can help.</p>

      <p><sup>1</sup> <i><u><strong>Journal of Sport and Health Science, Volume 8, Issue 3, May 2019, Pages 201-217.</strong></u></i></p>

    </div>`,
    url: '/blog/exercise-boosts-immunity',
  },
  {
    title: 'Mental Reframing',
    imageUrl: '/images/blogs/mental-reframing.jpeg',
    description: `<div>“Mental Reframing… or whatever you want to call it.”</div>

    <div>We all know that attitude is everything, but let’s be honest… “Fran” will never be awesome. What can be awesome about “Fran” (or any other difficult/knowingly painful workout) though, is your mindset before, during, and after it happens. It truly does matter how you mentally approach a workout and what you say to yourself and/or others about that workout. Phrases like, “I’m not going to finish this,” and “That’s going to be really heavy,” or “I never want to do that again,” all have an impact on how you and your body perceive the stress of the event.</div>
    
    <div>You may notice that when I am coaching, I always say things like, “It’s going to be awesome,” or “You’re gonna crush it,” or “The bar doesn’t stand a chance.” These types of responses are not only to make you laugh and motive you, but they also serve to try to get anyone that is in a defensive mindset to switch over to the offensive and attack the workout and lift with real intensity.</div>
    
    <div>Admittedly, this “technique” is not going to work perhaps even the first ten times you try it, but eventually, if you start doing it often enough, you will start finding yourself less afraid of unfavorable situations both inside and outside of the gym (you know… fake it till you make it, or whatever).</div>
    
    <div>Story time:</div>
    
    <div>When I was in BUD/s (SEAL training), there were a couple of phrases that really stuck with me and still do to this day. One of them being: “The only easy day was yesterday.” This was posted in big block letters over the parking lot where we met every morning. Another was: “The water temp is: warm and toasty.”</div>
    
    <div>Initially, I didn’t get it. Every morning, I would wake up ridiculously tired and sore all over…yesterday was DEFINITLY not easy. Furthermore, to start most days, the instructors would have us get “wet and sandy” and let me tell you… the water was farrrrr from “warm and toasty.” Why would such elite level operators hold these phrases at such high standards if they were not true? Fast forward to about a year later… having dropped out of BUD/s and started Rescue Swimmer school, it finally clicked. I finally started understanding why they said what they said. It took on a whole new meaning. It’s all psychological; it’s all about your mindset. I began approaching various situations with the mindset of “Bring it on!” or simply “Easy day” and I began to notice that my anxiety and fear before certain events, heavily weighted exams, painful workouts, and high stress situations all seemed easier and less complicated.</div>
    
    <div>There is one caviat, however. People will think you are a little bit crazy saying things like this because they may still be stuck in the defensive/nervous mindset. So don’t be that person that sits relaxed and content in their comfort zone, consistently making excuses why you can’t do something or that you’re not good enough. The reality is… you are good enough and you can be good enough; you just need to try, and fail, and then try again…until you make it.</div>
    
    <div>So yea… go crush it.</div>
    
    <div>And remember. If someone asks you if you think you can do something, your first thought should be (matter of fact), “I can do anything.”</div>`,
    url: '/blog/mental-reframing',
  },
];
