import lodashGet from 'lodash/get';

const checkStatus = res => {
  if (res.ok) {
    return res;
  }

  return res.text().then((data = '') => {
    throw new Error(`${res.status} ${data.replace(/\r?\n/g, '')}`);
  });
};

const xhr = (method, url, data, options) => {
  const headers = lodashGet(options, 'headers', {});
  const contentType =
    method !== 'get'
      ? headers['Content-Type'] || 'application/json'
      : undefined;
  const body = contentType === 'application/json' ? JSON.stringify(data) : data;

  return fetch(url, {
    method,
    ...(body && { body }),
    headers: {
      ...(contentType && { 'Content-Type': contentType }),
      ...headers
    }
  })
    .then(checkStatus)
    .then(res => res.json());
};

export const get = (url, options) => xhr('get', url, null, options);
export const post = (url, data, options) => xhr('post', url, data, options);
export const put = (url, data, options) => xhr('put', url, data, options);
