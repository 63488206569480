import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import * as ga from '../../utils/ga';

class GAPageViewTracker extends PureComponent {
  componentDidUpdate = prevProps => {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  };

  onRouteChanged = () => {
    const { pathname = '', hash = '' } = this.props.location;
    ga.pageview(`${pathname}${hash}`);
  };

  render = () => null;
}

export default withRouter(GAPageViewTracker);
