import React from 'react';
import Iframe from 'react-iframe';

const DAUNTLESS_API_KEY = 'AIzaSyC95r60uYUnB7WMJJu308Sz9PM1vo2NR1k';

const Map = () => (
  <Iframe
    url={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJnVZSbeXu4okRZsRPNBMYRSk&key=${DAUNTLESS_API_KEY}`}
    id="google-map"
    frameBorder="0"
    width="100%"
    height="400"
  />
);

export default Map;
