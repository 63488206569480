import React, { PureComponent } from 'react';
import * as ga from '../../utils/ga';
import './styles.scss';

const FORM_SUBMIT_EMAIL = 'dauntlessnh@gmail.com';

const ContactCard = () => (
  <section className="contact-card">
    <div style={{ marginBottom: '1rem' }}>
      <strong>Call Us:</strong>
      <br />
      <a
        href="tel:1-978-237-4644"
        rel="nofollow"
        onClick={() =>
          ga.click('Phone Number Link (Contact Us Page)', { useBeacon: true })
        }
      >
        (978) 237-4644
      </a>
    </div>
    <div>
      <strong>Email Us:</strong>
      <br />
      <a
        href="mailto:info@dauntlessnh.com"
        target="_blank"
        rel="noreferrer noopener"
        onClick={() =>
          ga.click('Email Link (Contact Us Page)', { useBeacon: true })
        }
      >
        info@dauntlessnh.com
      </a>
    </div>
  </section>
);

const ContactForm = ({ showOtherTextBox, toggleOtherTextBox }) => (
  <section className="contact-form">
    <h3>Contact Us</h3>
    <div className="contact-component--centered">
      <form
        action={`//formspree.io/${FORM_SUBMIT_EMAIL}`}
        method="POST"
        onSubmit={() =>
          ga.click('Contact Us Form', {
            eventCategory: 'submit',
            useBeacon: true,
          })
        }
      >
        <div className="contact-form-question">
          <label htmlFor="name">Name:</label>
          <br />
          <input
            type="text"
            required
            autoFocus
            style={{ width: '100%', height: '30px' }}
            name="name"
          />
        </div>
        <div className="contact-form-question">
          <label htmlFor="email">Email:</label>
          <br />
          <input
            type="email"
            required
            style={{ width: '100%', height: '30px' }}
            name="email"
          />
        </div>
        <div className="contact-form-question">
          <label htmlFor="phone">Phone:</label>
          <br />
          <input
            type="tel"
            style={{ width: '100%', height: '30px' }}
            name="phone "
          />
        </div>
        <div className="contact-form-question">
          <label htmlFor="interest">What are you interested in?</label>
          <br />
          <input
            className="m-x-10"
            type="checkbox"
            name="interest"
            value="free-intro"
          />
          Free Intro
          <br />
          <input
            className="m-x-10"
            type="checkbox"
            name="interest"
            value="group-fitness"
          />
          Group Fitness Classes
          <br />
          <input
            className="m-x-10"
            type="checkbox"
            name="interest"
            value="nutrition"
          />
          Nutrition
          <br />
          <input
            className="m-x-10"
            type="checkbox"
            name="interest"
            value="personal-training"
          />
          Personal Training
          <br />
          <input
            className="m-x-10"
            type="checkbox"
            name="interest"
            value="youth-training"
          />
          Youth Training
        </div>
        <div className="contact-form-question">
          <label htmlFor="interest">How did you hear about us?</label>
          <br />
          <input
            onClick={() => toggleOtherTextBox(false)}
            className="m-x-10"
            type="radio"
            name="hear-about-us"
            value="instagram"
          />
          Instagram
          <br />
          <input
            onClick={() => toggleOtherTextBox(false)}
            className="m-x-10"
            type="radio"
            name="hear-about-us"
            value="facebook"
          />
          Facebook
          <br />
          <input
            onClick={() => toggleOtherTextBox(false)}
            className="m-x-10"
            type="radio"
            name="hear-about-us"
            value="friend"
          />
          Friend
          <br />
          <input
            onClick={() => toggleOtherTextBox(true)}
            className="m-x-10"
            type="radio"
            name="hear-about-us"
            value="other"
          />
          Other
          {showOtherTextBox && (
            <input className="m-x-10" type="text" name="hear-about-us-other" />
          )}
          <br />
        </div>
        <div className="contact-form-question">
          <label htmlFor="message">Tell us more...</label>
          <br />
          <textarea
            style={{ width: '100%', minHeight: '80px' }}
            name="message"
          ></textarea>
        </div>
        <div style={{ direction: 'rtl' }}>
          <input
            type="hidden"
            name="_next"
            value="https://dauntlessnh.com/thank-you"
          />
          <button className="button" type="submit">
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  </section>
);

class ContactUs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { showOtherTextBox: false };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  toggleOtherTextBox = show => {
    this.setState({ showOtherTextBox: show });
  };

  render = () => (
    <div className="contact-us">
      <ContactCard />
      <ContactForm
        showOtherTextBox={this.state.showOtherTextBox}
        toggleOtherTextBox={this.toggleOtherTextBox}
      />
    </div>
  );
}

export default ContactUs;
