import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import * as ga from '../../utils/ga';
import './styles.scss';

class ContactButton extends PureComponent {
  handleClick = () => {
    ga.click('Contact Us Button');
    this.props.history.push('/contact-us');
  };

  render = () => (
    <Button
      id="contact-button"
      className={this.props.className}
      variant="contained"
      onClick={this.handleClick}
    >
      CONTACT US!
    </Button>
  );
}

export default withRouter(ContactButton);
