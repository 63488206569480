import React from 'react';
import { withRouter } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import stripHTML from '../../../../utils/stripHTML';
import './styles.scss';

const MAX_DESCRIPTION_WORD_LEN = 24;

const BlogCard = ({ title, date, imageUrl, description, url, history }) => (
  <Card className="blog-card" raised onClick={() => history.push(url)}>
    <CardHeader title={title} subheader={date} />
    <CardMedia
      style={{ height: 0, paddingTop: '56.25%' }}
      image={imageUrl}
      title={title}
    />
    <CardContent className="blog-description">
      <Typography variant="body2" color="textSecondary" component="div">
        <div
          dangerouslySetInnerHTML={{
            __html: `${stripHTML(description)
              .split(/\s/)
              .slice(0, MAX_DESCRIPTION_WORD_LEN)
              .join(' ')}…`,
          }}
        />
      </Typography>
    </CardContent>
  </Card>
);

export default withRouter(BlogCard);
