import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ScrollLink from '../../ScrollLink';
import MENU from '../../../constants/menu';
import './styles.scss';

class MenuDrawer extends PureComponent {
  handleItemClick = onClick => () => {
    this.props.onCloseClick();
    if (onClick) onClick();
  };

  render = () => {
    const { open, onCloseClick } = this.props;

    const MenuItem = ({ path, to, children }) => (
      <ScrollLink
        path={path}
        to={to}
        render={onClick => (
          <ListItem
            button
            onClick={() => {
              this.props.onCloseClick();
              onClick();
            }}
          >
            {children}
          </ListItem>
        )}
      />
    );

    return (
      <Drawer
        className="menu-drawer"
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: 'drawer-paper',
        }}
      >
        <div className="drawer-header">
          <IconButton onClick={onCloseClick}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {MENU.map(({ label, path, subItems }) => (
            <div key={label}>
              <MenuItem path={path}>
                <ListItemText primary={label} />
              </MenuItem>
              {subItems &&
                subItems.map(({ label, to }) => (
                  <MenuItem key={to} path={path} to={to}>
                    <ListItemText className="sub-item" primary={label} />
                  </MenuItem>
                ))}
            </div>
          ))}
        </List>
      </Drawer>
    );
  };
}

export default withRouter(MenuDrawer);
