import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import testimonials from '../../constants/testimonials';
import './styles.scss';

class Testimonial extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const testimonial = find(testimonials, [
      'url',
      this.props.location.pathname,
    ]);

    if (!testimonial) {
      this.props.history.push('/community');
      return null;
    }

    const { title, imageUrl, imageUrlFull, description } = testimonial;

    return (
      <div className="testimonial">
        {(imageUrlFull || imageUrl) && (
          <img src={imageUrlFull || imageUrl} alt={title} />
        )}
        <h1>{title}</h1>
        <section
          className="description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    );
  }
}

export default withRouter(Testimonial);
