import React from 'react';
import { Element } from 'react-scroll';
import testimonials from '../../../constants/testimonials';
import TestimonialCard from './TestimonialCard';
import './styles.scss';

const Testimonials = () => (
  <section className="testimonials">
    <Element name="testimonials">
      <h3>TESTIMONIALS</h3>

      <div className="testimonial-cards">
        {testimonials.map(testimonial => (
          <TestimonialCard key={testimonial.url} {...testimonial} />
        ))}
      </div>
    </Element>
  </section>
);

export default Testimonials;
