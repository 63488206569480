import React from 'react';
import { Element } from 'react-scroll';
import blogs from '../../../constants/blogs';
import BlogCard from './BlogCard';
import './styles.scss';

const Blog = () => (
  <section className="blog">
    <Element name="blog">
      <h3>BLOG</h3>

      <div className="blog-cards">
        {blogs.map(blog => (
          <BlogCard key={blog.url} {...blog} />
        ))}
      </div>
    </Element>
  </section>
);

export default Blog;
