import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

class HashStripper extends PureComponent {
  componentDidMount() {
    const { history } = this.props;
    if (history.location.hash) {
      history.push(history.location.path);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(HashStripper);
