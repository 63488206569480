import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import './styles.scss';

const CoachCard = ({ coach, onClick }) => (
  <Card className="coach-card" raised onClick={() => onClick(coach)}>
    <CardHeader title={coach.name} />
    <CardMedia
      style={{ height: 0, paddingTop: '56.25%' }}
      image={coach.imageUrl}
      title={coach.name}
    />
    <CardContent className="coach-tagline">
      <Typography variant="body2" color="textSecondary" component="div">
        <div dangerouslySetInnerHTML={{ __html: coach.tagline }} />
      </Typography>
    </CardContent>
  </Card>
);

export default CoachCard;
