import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu, { MenuDrawer } from '../Menu';
import './styles.scss';

export default function PrimarySearchAppBar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <>
      <AppBar id="header" position="static" elevation={0}>
        <Toolbar className="toolbar">
          <Link to="/">
            <img
              src="/images/header-logo.png"
              alt="Dauntless Academy Logo"
              className="logo"
            />
          </Link>
          <Menu toggleDrawer={toggleDrawer} />
        </Toolbar>
      </AppBar>
      <MenuDrawer open={drawerOpen} onCloseClick={toggleDrawer} />
    </>
  );
}
