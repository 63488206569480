import React from 'react';
import './styles.scss';

const GroupFitnessScheduleTable = () => (
  <table className="group-fitness-schedule-table">
    <tbody>
      <tr>
        <td></td>
        <th>Mon</th>
        <th>Tue</th>
        <th>Wed</th>
        <th>Thu</th>
        <th>Fri</th>
        <th>Sat</th>
        <th>Sun</th>
      </tr>
      <tr className="line-separator">
        <td colSpan="8"></td>
      </tr>
      <tr>
        <th>5:30am</th>
        {/* Monday */}
        <td>✔</td>
        {/* Tuesday */}
        <td>✔</td>
        {/* Wednesday */}
        <td>✔</td>
        {/* Thursday */}
        <td>✔</td>
        {/* Friday */}
        <td>✔</td>
        {/* Saturday */}
        <td></td>
        {/* Sunday */}
        <td></td>
      </tr>
      <tr className="line-separator">
        <td colSpan="8"></td>
      </tr>
      <tr>
        <th>7:00am</th>
        {/* Monday */}
        <td>✔</td>
        {/* Tuesday */}
        <td>✔</td>
        {/* Wednesday */}
        <td>✔</td>
        {/* Thursday */}
        <td></td>
        {/* Friday */}
        <td>✔</td>
        {/* Saturday */}
        <td>✔</td>
        {/* Sunday */}
        <td></td>
      </tr>
      <tr className="line-separator">
        <td colSpan="8"></td>
      </tr>
      <tr>
        <th>8:30am</th>
        {/* Monday */}
        <td>✔</td>
        {/* Tuesday */}
        <td></td>
        {/* Wednesday */}
        <td>✔</td>
        {/* Thursday */}
        <td></td>
        {/* Friday */}
        <td>✔</td>
        {/* Saturday */}
        <td></td>
        {/* Sunday */}
        <td></td>
      </tr>
      <tr className="line-separator">
        <td colSpan="8"></td>
      </tr>
      <tr>
        <th>9:00am</th>
        {/* Monday */}
        <td></td>
        {/* Tuesday */}
        <td></td>
        {/* Wednesday */}
        <td></td>
        {/* Thursday */}
        <td></td>
        {/* Friday */}
        <td></td>
        {/* Saturday */}
        <td>✔</td>
        {/* Sunday */}
        <td></td>
      </tr>
      <tr className="line-separator">
        <td colSpan="8"></td>
      </tr>
      <tr>
        <th>3:30pm</th>
        {/* Monday */}
        <td></td>
        {/* Tuesday */}
        <td></td>
        {/* Wednesday */}
        <td>K*</td>
        {/* Thursday */}
        <td></td>
        {/* Friday */}
        <td></td>
        {/* Saturday */}
        <td></td>
        {/* Sunday */}
        <td></td>
      </tr>
      <tr className="line-separator">
        <td colSpan="8"></td>
      </tr>
      <tr>
        <th>5:00pm</th>
        {/* Monday */}
        <td>✔</td>
        {/* Tuesday */}
        <td>✔</td>
        {/* Wednesday */}
        <td>✔</td>
        {/* Thursday */}
        <td>✔</td>
        {/* Friday */}
        <td>✔</td>
        {/* Saturday */}
        <td></td>
        {/* Sunday */}
        <td></td>
      </tr>
      <tr className="line-separator">
        <td colSpan="8"></td>
      </tr>
      <tr>
        <th>6:15pm</th>
        <td></td>
        {/* Monday */}
        <td>Y*</td>
        {/* Tuesday */}
        <td></td>
        {/* Wednesday */}
        <td>Y*</td>
        {/* Thursday */}
        <td></td>
        {/* Friday */}
        <td></td>
        {/* Saturday */}
        <td></td>
        {/* Sunday */}
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colSpan="8">
          <div style={{ fontStyle: 'italic', lineHeight: 1 }}>
            * Y: Dauntless Youth
          </div>
          <div style={{ fontStyle: 'italic', lineHeight: 1 }}>
            * K: Dauntless Kids
          </div>
          <div>
            This is our adjusted schedule due to COVID-19. Please see latest
            schedule updates on{' '}
            <a
              href="https://www.wellnessliving.com/login/dauntlessnh"
              rel="noopener noreferrer"
              target="_blank"
            >
              Wellness Living
            </a>
            .
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
);

export default GroupFitnessScheduleTable;
