import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import scrollTo from '../../utils/scrollTo';
import Blog from './Blog';
import Coaches from './Coaches';
import Mobility from './Mobility';
import Testimonials from './Testimonials';
import UpcomingEvents from './UpcomingEvents';
import './styles.scss';

class Community extends PureComponent {
  componentDidMount() {
    if (this.props.history.location.hash) {
      scrollTo(this.props.history.location.hash.substr(1));
    }
  }

  render() {
    return (
      <div className="community">
        <UpcomingEvents />
        <Testimonials />
        <Blog />
        <Coaches />
        <Mobility />
      </div>
    );
  }
}

export default withRouter(Community);
