import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import * as TRACKS from '../../../constants/tracks';
import './styles.scss';

const WorkoutSection = ({ wodset }) => {
  const entries = get(wodset, 'entries') || [];

  if (entries.length === 0) {
    return <p>Nothing assigned for this date.</p>;
  }

  return entries.map(({ workout }) => (
    <div key={workout.workout_name}>
      <h2 className="workout-name">{workout.workout_name}</h2>
      <p className="workout-description" style={{ whiteSpace: 'pre-wrap' }}>
        {workout.workout_description}
      </p>
    </div>
  ));
};

const WorkoutSummary = ({ data }) => {
  const wodsets = get(data, 'wodsets') || [];

  if (wodsets.length === 0) {
    return null;
  }

  const date = get(wodsets, '[0].date_string') || '';
  const mainWorkout = find(wodsets, ['track_name', TRACKS.WOD.name]);
  const SKILL_OF_THE_WEEK = find(wodsets, [
    'track_name',
    TRACKS.SKILL_OF_THE_WEEK.name,
  ]);

  return (
    <div className="workout-summary">
      <h1>{date}</h1>
      <WorkoutSection wodset={mainWorkout} />
      <h1 style={{ marginTop: '2em' }}>{TRACKS.SKILL_OF_THE_WEEK.name}</h1>
      <WorkoutSection wodset={SKILL_OF_THE_WEEK} />
    </div>
  );
};

export default WorkoutSummary;
