import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import * as ga from '../../../../utils/ga';
import './styles.scss';

const EventCard = ({ id, title, date, imageUrl, description, url }) => (
  <Card
    className="event-card"
    raised
    onClick={() => {
      ga.click(`[EVENT] ${id}`, { useBeacon: true });
      window.open(url, '_blank');
    }}
  >
    <CardHeader
      title={title}
      subheader={date}
      titleTypographyProps={{ className: 'event-title' }}
      subheaderTypographyProps={{ className: 'event-date' }}
    />
    <CardMedia
      style={{ height: 0, paddingTop: '56.25%' }}
      image={imageUrl}
      title={title}
    />
    <CardContent className="event-description">
      <Typography variant="body2" color="textSecondary" component="div">
        {description}
      </Typography>
    </CardContent>
  </Card>
);

export default EventCard;
