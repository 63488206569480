import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import { Slide } from 'react-slideshow-image';
import GroupFitnessScheduleTable from '../GroupFitnessScheduleTable';
import GoogleMap from '../GoogleMap';
import Workout from '../Workout';
import * as ga from '../../utils/ga';
import scrollTo from '../../utils/scrollTo';
import './styles.scss';

const Heading = () => (
  <div className="headings-wrapper">
    <h1>DAUNTLESS ACADEMY</h1>
    <h3>BE FEARLESS</h3>
  </div>
);

const AddressLink = () => (
  <strong>
    <a
      style={{ whiteSpace: 'nowrap' }}
      href="https://goo.gl/maps/yEGJGHTK8Z1N2g5w7"
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => ga.click('Address Link (About Us)', { useBeacon: true })}
    >
      38 Portsmouth Ave, Exeter
    </a>
  </strong>
);

const AboutUs = () => (
  <section className="about-us">
    <Element name="about-us">
      <h3>ABOUT US</h3>
    </Element>
    <div className="description">
      <p>
        Dauntless Academy, opened since April 2018, is a group fitness-based
        facility offering personal training and group fitness through
        professional and certified trainers, and nutrition coaching via the
        in-house registered dietitian (RD). With a newly expanded space located
        at {<AddressLink />}, Dauntless offers functional fitness classes where
        members have the opportunity to safely challenge themselves regardless
        of their fitness level, background or age.
      </p>

      <p>
        Our classes provide a wide range of movements, weights, and time
        domains. A qualified instructor informs and demonstrates all of the
        components of the workouts in every class.
      </p>

      <p>
        At Dauntless Academy, we provide a very unique approach to our
        movements. We subscribe to the{' '}
        <i>Mechanics, Consistency, and Intensity</i> model.
      </p>
      <p></p>
      <p>
        <strong style={{ fontFamily: 'sans-serif' }}>Mechanics.</strong> First,
        we teach our members how to position their body, apply force in the
        appropriate direction, and where to move to complete the exercise.
      </p>

      <p>
        <strong style={{ fontFamily: 'sans-serif' }}>Consistency.</strong> Next,
        we observe multiple reps to identify any movement errors or
        inefficiencies.
      </p>

      <p>
        <strong style={{ fontFamily: 'sans-serif' }}>Intensity.</strong> Lastly,
        we slowly adjust the intensity (via weight, speed, repetition)
        appropriately for the individual athletes.
      </p>

      <p>
        Dauntless’ in-house registered dietitian offers nutritional guidance and
        solutions to anyone in need. Our main approach to nutrition is the Plate
        Method. We are committed to working with clients at their nutritional
        level. No forced calorie counting or forbidden foods. Depending on your
        specific needs, we can provide meal plans, shopping lists, meal prep
        strategies, accountability, caloric intake recommendations and much
        more.
      </p>

      <p>
        Overall, our focus is to improve the individual. Whether that be
        mentally, physically or both. We hope you’ll consider joining our
        community and learn skills that will benefit you for a lifetime.
      </p>
    </div>
  </section>
);

const GroupFitnessClasses = () => (
  <section className="group-fitness-classes">
    <Element name="group-fitness-classes">
      <h3>GROUP FITNESS CLASSES</h3>
    </Element>
    <GroupFitnessScheduleTable />
  </section>
);

const PersonalTraining = () => (
  <section className="personal-training">
    <Element name="personal-training">
      <h3>PERSONAL TRAINING</h3>
    </Element>
    <div className="description">
      Have a specific goal in mind? Work 1-on-1 with the coach of your choice to
      come up with a customized plan to meet all of your individual goals.
      Options of 1, 2, 3 or 4 times a week available. All training is done on
      your schedule.
    </div>
  </section>
);

const DauntlessYouth = () => (
  <section className="dauntless-youth">
    <Element name="dauntless-youth">
      <h3>DAUNTLESS YOUTH</h3>
    </Element>
    <div className="description">
      Youth strength and conditioning training program designed for young
      athletes ages 10-17 who are looking for a full body strength regime. The
      class is administered by coaches educated specifically in youth strength
      training on the proper mechanics and form to help athletes improve
      performance and muscle strength, overall fitness, increased
      synchronization of motor units and more.
    </div>
  </section>
);

const DauntlessKids = () => (
  <section className="dauntless-kids">
    <Element name="dauntless-kids">
      <h3>DAUNTLESS KIDS</h3>
    </Element>
    <div className="description">
      DAUNTLESS KIDS is a 60 minute class, running once weekly geared towards
      cherubs ages 5 to 10 who love obstacles, while keeping them socially
      distant. Basic structure of the class will be a warm-up, followed by
      stretching and then onto cardio activities. These activities will take the
      form of races, obstacles, and challenges (for their inner super hero),
      plus so much more. Class will focus on basic movements that leverage body
      weight movements.
    </div>
  </section>
);

const WorkoutSection = () => (
  <section className="workout">
    <Element name="workout">
      <h3>WORKOUT</h3>
    </Element>
    <Workout />
  </section>
);

const Location = () => (
  <section className="location">
    <Element name="location">
      <h3>LOCATION</h3>
    </Element>
    <div className="description">
      Conveniently located near the heart of downtown Exeter, NH, we are off of
      exit 11 on US-101. Plenty of parking next to the building.
    </div>
    <GoogleMap />
  </section>
);

const Carousel = () => (
  <Slide
    className="carousel"
    duration={5500}
    transitionDuration={500}
    infinite={true}
    indicators={true}
    arrows={true}
  >
    {[
      { title: 'turkish getup demo', image: '/images/carousel/photo-01.png' },
      {
        title: 'bar muscle up success',
        image: '/images/carousel/photo-02.jpg',
      },
      { title: 'community', image: '/images/carousel/photo-03.jpg' },
      { title: 'race day', image: '/images/carousel/photo-04.jpg' },
      { title: 'bar muscle up', image: '/images/carousel/photo-05.jpg' },
      { title: 'teaching class', image: '/images/carousel/photo-06.jpg' },
      { title: 'kids fitness', image: '/images/carousel/photo-07.jpg' },
      { title: 'kids fitness', image: '/images/carousel/photo-08.jpg' },
    ].map(({ title, image }) => (
      <div key={title} className="slide-image-wrapper">
        <img className="slide-image" alt={title} src={image} />
      </div>
    ))}
  </Slide>
);

class Fitness extends PureComponent {
  componentDidMount() {
    if (this.props.history.location.hash) {
      scrollTo(this.props.history.location.hash.substr(1));
    }
  }

  render() {
    return (
      <div className="fitness">
        <Heading />
        <Carousel />
        <AboutUs />
        <GroupFitnessClasses />
        <PersonalTraining />
        <DauntlessYouth />
        <DauntlessKids />
        <WorkoutSection />
        <Location />
      </div>
    );
  }
}

export default withRouter(Fitness);
