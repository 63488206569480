import { withRouter } from 'react-router-dom';
import scrollTo from '../../utils/scrollTo';

const ScrollLink = ({ history, path, to, render }) => {
  const onClick = () => {
    if (history.location.pathname === path) {
      if (to) {
        history.replace(`#${to}`);
        scrollTo(to);
      } else {
        history.push(path);
      }
    } else {
      if (to) {
        history.push(`${path}#${to}`);
      } else {
        history.push(path);
      }
    }
  };

  return render(onClick);
};

export default withRouter(ScrollLink);
